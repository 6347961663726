import React from "react";
import Slider from "react-slick";

import Layout from "../components/layout";
import SEO from "../components/seo";

import groceriesBagIcon from "../images/icons/icon_groceries.svg";
import alexFace from "../images/faces/1.png";
import alondraFace from "../images/faces/2.png";
import camilleFace from "../images/faces/3.png";
import jeffFace from "../images/faces/4.png";
import milanFace from "../images/faces/5.png";
import tarynFace from "../images/faces/6.png";
import appleLogo from "../images/logo-apple.svg";
import playStoreLogo from "../images/logo-play-store.svg";
import submitIcon from "../images/icons/icon-circled-submit.svg";
import billIcon from "../images/icons/icon-circled-bill.svg";
import recordIcon from "../images/icons/icon-circled-record.svg";
import scanIcon from "../images/icons/icon-circled-scan.svg";
import appVideo from "../videos/20helps.mp4";
import appVideoPoster from "../images/20Helps-Poster.jpg";
import bookIcon from "../images/icons/icon_book.svg";
import tipIcon from "../images/icons/icon_cashonly.svg";
import ticketsIcon from "../images/icons/icon_tickets.svg";
import iceCreamIcon from "../images/icons/icon_icecream.svg";
import cannedFoodIcon from "../images/icons/icon_cannedfood.svg";
import lunchIcon from "../images/icons/icon_lunch.svg";
import cookieIcon from "../images/icons/icon_cookie.svg";
import strandedIcon from "../images/icons/icon_stranded.svg";
import lemonadeIcon from "../images/icons/icon_lemonade.svg";
import gasIcon from "../images/icons/icon_gas.svg";
import snacksIcon from "../images/icons/icon_snacks.svg";
import chocolateIcon from "../images/icons/icon_chocolate.svg";
import phoneCallIcon from "../images/icons/icon_phonecall.svg";
import tabIcon from "../images/icons/icon_tab.svg";
import toyIcon from "../images/icons/icon_toy.svg";
import rideshareIcon from "../images/icons/icon_rideshaare.svg";
import brownieIcon from "../images/icons/icon_brownie.svg";
import decorateIcon from "../images/icons/icon_decorate.svg";
// import iceCreamIcon from "../images/icons/icon_.svg";



function IndexPage() {
	var sliderSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true
	};

	return (
		<Layout>
			<SEO keywords={[`20Helps`, `97thFloor`]} title="20Helps" />

			<section>
				<div className="container">
					<h1 className="text-primary font-serif leading-tight mt-8 mb-16 md:text-3xl">
						We live in an artificially connected world. <br />
						<span className="italic font-light underline underline-secondary">
							We believe that a $20 bill can change that.
						</span>
					</h1>

					<div className="hero-video rounded bg-grayCustom w-full">
						<iframe
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
							frameborder="0"
							id="hero-iframe"
							src="https://www.youtube.com/embed/f-4EQiOtPTU"
							width="100%"
						></iframe>
					</div>
				</div>
			</section>

			<section className="bg-primary text-white py-24 mt-20">
				<div className="container">
					<div className="max-w-5xl mx-auto">
						<p className="text-secondary text-3xl leading-relaxed font-medium mb-8 md:text-2xl">
							FIVE YEARS AGO 97TH FLOOR LAUNCHED #20HELPS WITH A SIMPLE CHALLENGE:
						</p>
						<h2 className="text-5xl italic font-light font-serif leading-tight md:text-3xl">
							Demonstrate the positive power of money by setting aside a $20 bill for good.
						</h2>
					</div>
				</div>
			</section>

			<section className="py-20 text-primary">
				<div className="container">
					<div className="max-w-5xl mx-auto">
						<h2 className="text-4xl italic font-light font-serif md:text-3xl">
							#20Helps continues to grow.{` `}
						</h2>
						<p className="leading-loose md:text-base">
							Today our world is artificially connected, and lacking in human connection. We believe that a
							$20 bill can change that.
						</p>

						<h2 className="text-4xl italic font-light font-serif mt-10 md:text-3xl">
							It’s true, $20 may not seem like much.
						</h2>
						<p className="leading-loose md:text-base">
							But a $20 bill can impact the right person if gifted with the love and attention that can
							only come through human connection.
						</p>

						<h2 className="text-4xl italic font-light font-serif mt-10 md:text-3xl">
							#20Helps is an invitation to connect.
						</h2>
						<p className="leading-loose md:text-base">
							Please join us by setting aside $20, and together we can make lasting connections that will
							impact lives and elevate each other.
						</p>
					</div>
				</div>
			</section>

			<section className="bg-primary text-white py-24">
				<div className="container">
					<div className="max-w-5xl mx-auto">
						<h2 className="text-4xl italic font-light font-serif text-center md:text-3xl">
							Maybe you don’t have $20, but you have 20 minutes. We invite you to give 20 minutes of your
							time to someone who needs it.
						</h2>
					</div>
				</div>
			</section>

			<section className="text-primary text-center pt-24 pb-10 md:pt-12">
				<div className="container">
					<div className="max-w-4xl mx-auto">
						<p className="text-3xl inline-block border-b-2 border-solid border-secondary leading-tight md:text-2xl">
							MAKE AN IMPACT
						</p>
						<h2 className="text-4xl italic font-light font-serif mt-4 md:text-3xl md:mt-0">
							20 ways to help
						</h2>
					</div>
				</div>
			</section>

			<section className="text-center bg-secondary relative py-20">
				<Slider {...sliderSettings}>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img
								alt="Groceries Bag"
								className="h-24 inline mr-10 md:block md:mx-auto"
								src={groceriesBagIcon}
							/>
							{` `}A bag of groceries for a neighbor
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={bookIcon} />A book
							you loved to help a struggling friend
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={tipIcon} />
							An extra large tip your server
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={ticketsIcon} />
							Movie tickets (and baby sitting) for new parents
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={iceCreamIcon} />
							Ice cream and a listening ear for a friend
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={cannedFoodIcon} />
							Canned food to your local food bank
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={lunchIcon} />
							Lunch with a struggling friend
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={cookieIcon} />
							Fresh cookies for a new neighbor
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={strandedIcon} />
							Help for a stranded stranger
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={lemonadeIcon} />
							Buy something at a kid’s lemonde or hot cocoa stand
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={gasIcon} />
							Help fill up a tank of gas
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={snacksIcon} />
							Extra snacks for your coworkers
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={chocolateIcon} />A fine
							dark chocolate bar for your friend on a diet
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={phoneCallIcon} />A phone
							call to a parent or grandparent
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={tabIcon} />
							Help with someone’s tab at a restaurant
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={toyIcon} />A toy for
							a child
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={rideshareIcon} />A large
							tip for your kind rideshare driver
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={tipIcon} />
							Helping someone who didn’t know it was “cash only”
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={brownieIcon} />
							Brownies for your local fire station
						</h3>
					</div>
					<div className="px-24">
						<h3 className="text-primary italic font-light font-serif text-4xl md:text-3xl">
							<img alt="Book" className="h-24 inline mr-10 md:block  md:mx-auto" src={decorateIcon} />
							Decorate someone’s home for the holidays
						</h3>
					</div>
				</Slider>
			</section>

			<section className="py-24">
				<div className="container max-w-5xl">
					<div className="flex -mx-2 my-4 md:flex-wrap">
						<div className="w-1/3 px-2 md:w-full md:mb-4">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={alexFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/randfish/status/671497764811857922" rel="noopener noreferrer" target="_blank">@97thFloor thanks for the awesome gifts! Love the #20Helps campaign :-)</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="w-1/3 px-2 md:w-full  md:mb-4">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={alondraFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/AnnaleePeters2/status/541090056316801025" rel="noopener noreferrer" target="_blank">Giving gifts to the elderly! #20helps</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="w-1/3 px-2 md:w-full">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={camilleFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/maggieacall/status/677240642750169088" rel="noopener noreferrer" target="_blank">Shoveling my neighbors driveway of snow piled over a foot high! Give simple. #20helps</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex -mx-2 my-4 md:flex-wrap">
						<div className="w-1/3 px-2 md:w-full md:mb-4">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={jeffFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/BarrettDastrup/status/541040476715880448" rel="noopener noreferrer" target="_blank">Ride to the hotel and room fee for this sweet lady named Peaches. #20Helps</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="w-1/3 px-2 md:w-full md:mb-4">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={milanFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/JaymeJenkins/status/546358100341452800" rel="noopener noreferrer" target="_blank">Donated $20 to help @utahhumane find pets loving homes.#20Helps</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="w-1/3 px-2 md:w-full">
							<div className="relative">
								<img alt="" className="md:object-cover md:w-full" src={tarynFace} />
								<div className="bg-primary-80 top-0 left-0 absolute w-full h-full text-white flex justify-center content-center items-center opacity-0 hover:opacity-100">
									<div className="p-8 text-center">
										<p><a href="https://twitter.com/LindseyCowley/status/541347559000641536" rel="noopener noreferrer" target="_blank">Merry Christmas Abigail! I hope you and your new friends stay warm this winter. #20helps</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pb-2">
				<div className="container max-w-5xl flex flex-wrap mb-4">
					<div class="w-1/2 pr-10 md:w-full">
						<h2 className="text-3xl inline-block border-b-2 border-solid border-secondary leading-tight mb-12">
							TRACK THE IMPACT
						</h2>
						<p className="leading-loose mb-12">
							Last year we paired the #20Helps campaign with an app that lets you track the impact your
							physical $20 bill has made in the life of others. See what good it has done.
						</p>
						<ul className="italic font-light font-serif">
							<li className="flex items-center content-center mb-4">
								<img alt="" className="mr-5" src={scanIcon} /> <p>Start by scanning your bill.</p>
							</li>
							<li className="flex items-center content-center mb-4">
								<img alt="" className="mr-5" src={recordIcon} /> <p>Record your good deed.</p>
							</li>
							<li className="flex items-center content-center mb-4">
								<img alt="" className="mr-5" src={submitIcon} /> <p>Submit your story.</p>
							</li>
							<li className="flex items-center content-center mb-4">
								<img alt="" className="mr-5" src={billIcon} /> <p>Then use your bill to help someone.</p>
							</li>
						</ul>
					</div>
					<div class="w-1/2 md:w-full">
						<video
							controls
							height="100%"
							id="20helpsVideo"
							onclick="this.paused?this.play():this.pause();"
							onended="this.load();"
							poster={appVideoPoster}
							width="100%"
						>
							<source src={appVideo} type="video/mp4" />
						</video>
					</div>
					<div class="w-full flex flex-wrap justify-center mt-24">
						<a
							className="flex items-center content-center p-5 border-solid border-4 border-secondary mx-12 my-6 md:mx-0 md:p-3"
							href="https://itunes.apple.com/us/app/20helps/id1294846073?mt=8"
							rel="noopener noreferrer"
							target="_blank"
						>
							<img alt="" className="mr-6" src={appleLogo} />
							{` `}
							<p>
								Download on <br />
								the App Store
							</p>
						</a>
						<a
							className="flex items-center content-center p-5 border-solid border-4 border-secondary mx-12 my-6 md:mx-0  md:p-3"
							href="https://play.google.com/store/apps/details?id=com.ninetyseventhfloor.twentyhelps"
							rel="noopener noreferrer"
							target="_blank"
						>
							<img alt="" className="mr-6" src={playStoreLogo} />
							<p>
								Download on <br />
								Google Play
							</p>
						</a>
					</div>
				</div>
			</section>

			<section className="bg-primary text-white py-24 mt-20">
				<div className="container">
					<div className="max-w-5xl mx-auto">
						<p className="text-secondary text-3xl leading-relaxed font-medium mb-8 md:text-2xl">
							MONEY HAS DONE ENOUGH HARM TO THE WORLD,IT’S TIME FOR IT TO DO SOME GOOD.
						</p>
						<h2 className="text-5xl italic font-light font-serif leading-tight md:text-3xl">
							Help us overcome money’s bad reputation by giving $20 or 20 minutes to someone who needs it.
							Together we can make connections and impact lives.
						</h2>
					</div>
				</div>
			</section>

			<section className="py-32 text-primary">
				<div className="container">
					<div className="text-center">
						<h2 className="text-3xl inline-block border-b-2 border-solid border-secondary leading-tight mb-4 bold font-medium md:text-2xl">
							CONTACT US
						</h2>
						<p className="leading-loose md:text-base">
							If you have any questions or want to get involved, please reach out. <br />
							Email us at <a href="mailto:20helps@97thfloor.com">20helps@97thfloor.com</a>.
						</p>
					</div>
				</div>
			</section>
		</Layout>
	);
}

export default IndexPage;
